<template>
  <div class="apply-wrapper">
    <ul class="apply-items">
      <li
        v-for="item in applys"
        :key="item.id">
        <div class="item-t1">
          <h3>食神通商家入驻申请</h3>
          <span class="time">{{ item.time }}</span>
        </div>
        <div class="item-t2">
          <span>
            状态：<b>{{ item.status | statusFil }}</b>
          </span>
          <!--          <span class="bind-btn" v-if="item.status === 1">-->
          <!--            绑定登录手机号<i class="el-icon-arrow-right"></i>-->
          <!--          </span>-->
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: '',
  data () {
    return {
      applys: []
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  filters: {
    statusFil (val) {
      if (val === -1) return '已拒绝'
      if (val === 0) return '待审核'
      if (val === 1) return '已通过'
      return val
    }
  },
  mounted() {
    this.getApplys()
  },
  methods: {
    getApplys () {
      this.$api.apply.lists({
        uid: this.userInfo.id,
        page: 1,
        limit: 100
      }).then(res => {
        if (res.data.success) {
          this.applys = res.data.result.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
